<template>
    <div class="rating__stars">
        <div class="container">
          <span class="star material-icons">star</span>
          <span class="star material-icons">star</span>
          <span class="star material-icons">star</span>
          <span class="star material-icons">star</span>
          <span class="star material-icons">star</span>
          <div
            class="mask"
            :style="`width:${width}%;${maskColor?`background-color:${maskColor}`:''}`"
          ></div>
        </div>
      </div>
</template>
<script>
export default {
  name: "Stars",
  props: {
    percentage: { required: true },
    maskColor:{}
  },
  computed: {
    width() {
        return 100 - +this.percentage;
    },
  },
};
</script>
<style scoped>
.rating__stars {
  text-align: center;
  color: goldenrod;
}
.star {
  width: 21px;
}
.container {
  display: inline-block;
  position: relative;
}
.mask {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}
</style>
