<template>
  <Navigation v-if="isAuthenticated"/>
  <router-view v-slot="{ Component }">
    <transition name="slide-fade" mode="out-in">
      <keep-alive include="Schedule">
        <component :is="Component"/>
      </keep-alive>
    </transition>
  </router-view>
  <TheFooter/>
  <transition name="bottom-slide" mode="out-in">
    <div class="notification" v-if="updateExists">
      <span>Update available</span>
      <button class="button" @click="refreshApp">{{$t('update')}}</button>
    </div>
  </transition>
  <transition name="bottom-slide" mode="out-in">
    <Notification v-if="activeNotification" :notification="activeNotification" @on-close="activeNotification=null"/>
  </transition>
</template>

<script>
import update from "./mixins/update";
import Navigation from "./components/Navigation";
import Notification from "@/components/Notification"
import TheFooter from "./components/TheFooter";

export default {
  mixins: [update],
  components: {
    Navigation,
    TheFooter,
    Notification
  },
  data(){
    return {
      activeNotification: null
    }
  },
  created() {
    this.tryLogin();
    document.addEventListener("visibilitychange", this.visibilityChanged);
  },
  beforeUnmount() {
    document.removeEventListener("visibilitychange", this.visibilityChanged);
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated && this.$store.getters['user/userData'];
    },
    userLang() {
      return this.$store.getters['user/userData']?.taal;
    }
  },

  watch: {
    userLang() {
      if (this.userLang) {
        this.$i18n.locale = this.userLang.toLowerCase();
      }
    },
    didAutoLogout(newValue, oldValue){
      if(newValue !== oldValue && newValue){
        this.$router.replace('/login');
      }
    }
  },

  methods: {
    async visibilityChanged() {
      if (document.visibilityState === "visible") {
        console.log("schedule focused from background.");
        await this.tryLogin();
      }
    },
    async tryLogin() {
      try {
        await this.$store.dispatch('tryLogin');
      } catch (e) {
        alert(e);
      }
    }
  }
}
</script>
<style lang="scss">
@import 'assets/scss/app';

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  left: 60%;
  opacity: 0;
}

.bottom-slide-enter-active, .bottom-slide-leave-active {
  transition: all 0.3s ease-out;
}

.bottom-slide-enter-from, .bottom-slide-leave-to {
  opacity: 0;
  bottom: -4rem;
}

.top-slide-enter-active, .top-slide-leave-active {
  transition: all 0.3s ease-out;
}

.top-slide-enter-from, .top-slide-leave-to {
  opacity: 0;
  top: -4rem;
}
</style>
