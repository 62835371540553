export default{
    ritten(state){
        return state.ritten;
    },
    rittenToDo(state){
        return state.ritten.filter(r => r.stops && r.stops.some(stop => stop.substatus !== 'aangekomen'));
    },
    rittenDone(state){
        return state.ritten.filter(r => r.stops && !r.stops.some(stop => stop.substatus !== 'aangekomen')).reverse();
    },
    loading(state){
        return state.loading;
    }
}