<template>
    <div class="modal">
    <div class="modal__backdrop" @click="$emit('on-close')"></div>
    <div class="modal__wrapper">
      <div class="modal__close">
        <button
          type="button"
          class="icon-button error"
          @click="$emit('on-close')"
        >
          <span class="material-icons">close</span>
        </button>
      </div>
      <h2 class="modal__title">{{ $t("links.title") }}</h2>
      <div class="modal__data" v-if="links">
        <ul>
          <li v-for="item in links" :key="item.caption">
            <a target="_blank" :href="item.url">{{
              item.caption
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
    export default{
        name:"LinksList",
        data(){
            return {
                links: []
            }
        },
        created(){
            this.getLinks();
        },
        methods:{
            async getLinks(){
                try{
                    this.links = await this.$store.dispatch('user/getLinks');
                }catch(e){
                    alert(e);
                }
            }
        }
    }
</script>
<style scoped>
.modal {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.modal__close {
  text-align: right;
  margin-bottom: 0.5rem;
}
.modal__backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.modal__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  width: 360px;

  background: white;
  padding: 0.5rem;
  border-radius: 1rem;
}

ul {
  display: grid;
  grid-gap: 1rem;
  max-height: 50vh;
  overflow-y: scroll;
  padding-right: 0.2rem;
}

li {
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: var(--color-primary);
  display: grid;
  grid-gap: 0.5rem;
}
li > span {
  font-weight: bold;
}
li a {
  color: white;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
</style>