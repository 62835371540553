import apihelper from "../../../helpers/apihelper";
export default{
    async getRitten(context){
        const token = context.rootGetters.token;
        const username = context.rootGetters.userName;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rideshistory/listrides?pers_id=${username}`;
        context.commit('setLoading', true);
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch user ritten'
        });
        context.commit('setLoading', false);
        context.commit('setRitten', responseData.data);
    },


    async getRitTimetable(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rideshistory/timetable/${id}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch rit timetable'
        });
        return responseData.data;
    },

    clearRitten(context) {
        context.commit('setRitten', []);
    },

    async updateNrplaat(context, {id,tempnrplaat}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rideshistory/updatenrplaat/${id}?tempnrplaat=${tempnrplaat}`;
        await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to update licence-plate.'
        });
        await context.dispatch('getRitten');
    },

    async confirmDeparture(context, {id, zip}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rideshistory/confirmdeparture/${id}?zip=${zip}`;
        await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to confirm departure.'
        });
        await context.dispatch('getRitten');
    },

    async updateState(context,payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rideshistory/updatestatus`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to update status.'
        });
        await context.dispatch('getRitten');
    },

    async cancelState(context,payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rideshistory/cancelstatus`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to cancel status.'
        });
        await context.dispatch('getRitten');
    },

    async goHome(context,payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rideshistory/goHome`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to change home status.'
        });
        await context.dispatch('getRitten');
    },

    async saveDriverInput(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rideshistory/savedriverinput`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to register input.'
        });
        await context.dispatch('getRitten');
    },

    async sendReceit(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rideshistory/proofofpayment`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to send receit.'
        });
    },

    async generateContract(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/preview/${id}?template=contract`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch contract.'
        });
        return responseData.data;
    },

    async generateRit(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/preview/${id}?template=billorit`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch data.'
        });
        return responseData.data;
    },
}