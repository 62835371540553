<template>
  <div class="notification">
    <span>{{ notification?.notification?.title }}</span>
    <button class="icon-button" @click="$emit('on-close')"><span class="material-icons error">close</span></button>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    notification: {required: true}
  }
}
</script>