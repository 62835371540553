<template>
  <div class="page login">
      <div class="login__form">
        <div class="login__form__logo">
          <img src="/logo.png" alt="V-tax Logo">
        </div>
        <form action="" @submit.prevent="submitLogin">
          <label for="username">{{ $t('login.username') }}</label>
          <input type="text" class="input" v-model="username">
          <label for="username">{{ $t('login.password') }}</label>
          <input type="password" class="input" v-model="password">
          <button class="button" :class="{'button--disabled':!loginActive}" @click.prevent="submitLogin">{{ $t('login.login') }}</button>
        </form>
      </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: ""
    }
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated && this.$store.getters['user/userData'];
    },
    loginActive() {
      return this.username !== '' && this.password !== '';
    }
  },
  watch: {
    isAuthenticated(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        if(this.$route.query.nextUrl){
          this.$router.replace(this.$route.query.nextUrl);
          return;
        }
        this.$router.replace('/');
      }
    }
  },

  methods: {
    async submitLogin() {
      if (this.username !== '' && this.password !== '') {
        this.authenticating = true;
        const payload = {
          username: this.username,
          password: this.password
        };
        try {
          await this.$store.dispatch('login', payload);
        } catch (e) {
          alert(e);
        }
        this.authenticating = false;
        this.password = "";
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/views/login.scss';
</style>