<template>
  <ul class="confirm" v-if="list && list.length">
    <li class="confirm__item" v-for="item in list" :key="item.id">
      <span class="confirm__item__loc">{{ item.traject }}</span>
      <span class="confirm__item__time"><b>{{ item.datum }} - {{ item.tijd }}</b></span>
      <span class="confirm__item__cust">{{ item.klant }}</span>
      <span class="confirm__item__cust">{{ item.soortwagen }}</span>

      <div class="confirm__item__controls">
        <button class="button button--accept" :disabled="loading" @click="toggleRit(item.id,'X')"><span class="material-icons">done</span>
        </button>
        <button class="button button--decline" :disabled="loading" @click="toggleRit(item.id,0)"><span
            class="material-icons">close</span></button>
      </div>
    </li>
  </ul>
  <p class="no-results" v-else>{{ $t('assignments.none') }}</p>
</template>

<script>
export default {
  name: "ConfirmList",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    list() {
      return this.$store.getters['ritten/confirmRitten'];
    }
  },

  methods: {
    async toggleRit(id,bevestigd) {
      this.loading = true;
      try {
        await this.$store.dispatch('ritten/toggleConfirm', {id,bevestigd});
        this.refreshList();
      } catch (e) {
        alert(e);
      }
      this.loading = false;
    },
    async refreshList() {
      this.loading = true;
      try {
        await this.$store.dispatch('ritten/getConfirmRitten');
        await this.$store.dispatch('ritten/getRitten');
      } catch (e) {
        alert(e);
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.confirm {
  height: 100%;
  position: relative;
  background-color: #e5e5e5;
  padding: 0.5rem;
  border-radius: 1rem;
  overflow: auto;
}

.confirm__item {
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0.5rem;
  margin: 0.5rem 0;
  background: white;
  display: grid;
  text-align: center;
  grid-gap: 0.2rem;
}

.confirm__item__controls {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-around;
  grid-gap: 1rem;
}

.confirm__item__controls button {
  width: 100px;
  height: 2.4rem;
}
</style>