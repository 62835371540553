<template>
  <div class="footer">
    <a class="button" href="tel:+3292454545"><span class="material-icons">phone</span> {{ $t('dispatch') }}</a>
    <a class="footer__help" target="_blank" :href="`/docs/HES-chauffeurs-handleiding_${$i18n.locale}.pdf`"><span class="material-icons">help</span></a>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>
<style lang="scss" scoped>
  @import '../assets/scss/components/footer';
</style>