<template>
<div class="nav">
  <router-link :class="{'nav__button--active':$route.name==='Home'}" to="/" class="nav__button"><span class="material-icons">home</span><div class="nav__button__notification" v-if="confirmCount">{{confirmCount}}</div></router-link>
  <router-link :class="{'nav__button--active':$route.name==='Schedule'}" to="/schedule" class="nav__button"><span class="material-icons">departure_board</span></router-link>
  <router-link :class="{'nav__button--active':$route.name==='Settings'}" to="/settings" class="nav__button"><span class="material-icons">manage_accounts</span></router-link>
</div>
</template>

<script>
export default {
  name: "Navigation",

  computed: {
    confirmCount(){
      return this.$store.getters['ritten/confirmRittenCount'];
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/components/navigation';
</style>