export default {
  async fetchPostRequest({ url, token, payload, errorMsg }) {
    const online = await this.checkOnlineStatus();
    if (online) {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: payload,
        });
        const data = await this.handleResponseData(response, errorMsg);
        return data;
      } catch (e) {
        alert(e);
      }
    } else {
      throw new Error("No internet connection.");
    }
  },

  async fetchGetRequest({ url, token, errorMsg }) {
    const online = await this.checkOnlineStatus();
    if (online) {
      try {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await this.handleResponseData(response, errorMsg);
        return data;
      } catch (e) {
        alert(e);
      }
    } else {
      throw new Error("No internet connection.");
    }
  },

  async handleResponseData(response, errorMsg) {
    const responseData = await response.json();
    if (!response.ok || !(responseData.status || responseData.result)) {
      const error = new Error(responseData.error_description || errorMsg);
      throw error;
    }
    return responseData;
  },

  async checkOnlineStatus() {
    try {
        const online = await fetch("/pxl.png");
        return online.status >= 200 && online.status < 300;
      } catch (err) {
        return false;
      }
  },
};
