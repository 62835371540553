import nl from './locales/nl.json';
import fr from './locales/fr.json';
import en from './locales/en.json';
import {createI18n} from "vue-i18n";

const defaultLocale = _getLocale();

const languages = {
    nl,
    fr,
    en,
};

export const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: 'en',
    messages: languages
})

function _getLocale(){
    const lang = navigator.language;
    if(lang.toLowerCase().match('nl')){
        return 'nl';
    }
    else if(lang.toLowerCase().match('fr')){
        return 'fr';
    }
    return 'en';
}