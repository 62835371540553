import {createRouter, createWebHistory} from 'vue-router';
import store from "@/store";
import Home from '../views/Home.vue';
import Login from "../views/Login";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresUnauth: true
        }
    },

    {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/Settings'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/schedule',
        name: 'Schedule',
        component: () => import('@/views/Schedule'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/history',
        name: 'History',
        component: () => import('@/views/History'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/rating',
        name: 'Rating',
        component: () => import('@/views/Rating'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/timetable',
        name: 'TimeTable',
        component: () => import('@/views/TimeTable'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/contracts',
        name: 'Contracts',
        component: () => import('@/views/Contracts'),
        meta: {
            requiresAuth: true
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(function (to, _, next) {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next({
            path: '/login',
            query: {
                nextUrl: to.fullPath
            }
        });
    } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
        next('/');
    } else {
        next();
    }
});

export default router
